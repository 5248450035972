<!--
 * @Descripttion:
 * @Author: 张明尧
 * @Date: 2020-12-25 11:34:05
 * @LastEditTime: 2021-01-29 09:49:45
-->
<template>
  <div class="myApp-dialogApp">
    <!-- 打包次数提示弹框 -->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="currentPanel.list.DB_dialog"
      width="40%"
      custom-class="useExplain_Task"
    >
      <div
        slot="title"
        class="useTitle"
      >
        打包次数提示
      </div>
      <div
        style="line-height: 2"
        class="color-3F3F3F padding-LR-20 padding-top-10 padding-bottom-30"
      >
        <!-- <div class="text-status-center font-size-18 font-weight-600">
          您当前打包次数不足，是否前往购买打包次数？
        </div> -->
        <div class="text-status-center font-size-18 font-weight-600">
          您当前打包次数不足，请前往联系客服购买打包次数!
        </div>
      </div>
      <!-- <div
        slot="footer"
        class="dialog-footer text-status-center"
      >
        <el-button
          style="background: #EF4D4D; color: #fff; border-color: #EF4D4D; padding: 5px 20px"
        >
          前 往
        </el-button>
      </div> -->
    </el-dialog>
    <!-- 服务器地址提示弹框 -->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="currentPanel.form.serve_dialog"
      width="40%"
      custom-class="useExplain_Task"
    >
      <div
        slot="title"
        class="useTitle"
      >
        服务器地址提示
      </div>
      <div
        style="line-height: 2"
        class="color-3F3F3F font-size-18 padding-bottom-30 padding-LR-20 padding-top-10"
      >
        <div class="text-status-center">
          您当前的服务器地址和APPID调用异常，是否继续打包？
        </div>
      </div>
      <div
        slot="footer"
        class="dialog-footer text-status-center"
      >
        <el-button
          @click="form_save()"
          style="background: #EF4D4D; color: #fff; border-color: #EF4D4D; padding: 10px 20px"
        >
          确 定
        </el-button>
        <el-button
          @click="currentPanel.form.serve_dialog = false"
          class="margin-left-40"
          style="padding: 10px 20px"
        >
          取 消
        </el-button>
      </div>
    </el-dialog>
    <!-- 服务器地址提示弹框 -->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="currentPanel.form.DB_lengthDialog.show"
      width="40%"
      custom-class="useExplain_Task"
    >
      <div
        slot="title"
        class="useTitle"
      >
        服务器打包提示
      </div>
      <div
        style="line-height: 2"
        class="color-3F3F3F font-size-18 padding-bottom-30 padding-LR-20 padding-top-10"
      >
        <div class="text-status-center">
          本次打包消耗{{ DB_length }}次机会，是否继续打包？
        </div>
      </div>
      <div
        slot="footer"
        class="dialog-footer text-status-center"
      >
        <el-button
          @click="DB_length_post()"
          style="background: #EF4D4D; color: #fff; border-color: #EF4D4D; padding: 10px 20px"
        >
          确 定
        </el-button>
        <el-button
          @click="currentPanel.form.DB_lengthDialog.show = false"
          class="margin-left-40"
          style="padding: 10px 20px"
        >
          取 消
        </el-button>
      </div>
    </el-dialog>
    <!-- 修改PC授权码弹框 -->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="currentPanel.list.editPCSQM_dialog.show"
      width="40%"
      custom-class="useExplain_Task"
    >
      <div
        slot="title"
        class="useTitle"
      >
        修改PC授权码
      </div>
      <div
        style="line-height: 2"
        class="color-3F3F3F font-size-18 padding-bottom-30 padding-LR-20 padding-top-10"
      >
        <div>
          <div>
            <span class="vertical-align-middle font-size-16">
              当系统更换PC证书后，之前的APP将不可使用，需要重新绑定PC授权码，然后再下载新的APP证书，重新导入。
            </span>
          </div>
          <el-row
            class="font-size-14 displayCenter-dialogApp margin-top-20"
          >
            <el-col
              :span="24"
            >
              <el-input
                v-model="editPCSQM"
                placeholder="请填写新的PC授权码: xxxx-xxxx-xxxx-xxxx"
              />
            </el-col>
          </el-row>
        </div>
      </div>
      <div
        slot="footer"
        class="dialog-footer text-status-center"
      >
        <el-button
          @click="editPCSQM_click()"
          style="background: #EF4D4D; color: #fff; border-color: #EF4D4D; padding: 10px 20px"
        >
          确 定
        </el-button>
        <el-button
          @click="() => {currentPanel.list.editPCSQM_dialog.show = false; editPCSQM = ''}"
          class="margin-left-40"
          style="padding: 10px 20px"
        >
          取 消
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getChangePcCredentials,
} from '@/pages/index/actions/actions';

export default {
  props: {
    currentPanel: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      editPCSQM: '', // 修改PC授权码
      // DB_length: 0,
    };
  },
  computed: {
    DB_length() {
      if (!this.currentPanel.form.serve_data.RECORD_TYPE_CODE) return 0;
      const data = this.currentPanel.form.serve_data.RECORD_TYPE_CODE && this.currentPanel.form.serve_data.RECORD_TYPE_CODE.split(',');
      return data.length;
    },
  },
  methods: {
    form_save() {
      this.currentPanel.form.serve_dialog = false;
      this.currentPanel.form.form_save_post();
    },
    // 打包次数提示确认按钮
    DB_length_post() {
      if (this.currentPanel.form.listCheck.SHOP_DBCS - this.currentPanel.form.listCheck.SHOP_YYDBCS < this.DB_length) {
        this.currentPanel.list.DB_dialog = true;
        this.currentPanel.form.DB_lengthDialog.show = false;
        return;
      }
      this.currentPanel.form.form_save_post();
      this.currentPanel.form.DB_lengthDialog.show = false;
    },
    // 修改PC授权码
    editPCSQM_click() {
      const successStatus = this.isPC_SQM(this.editPCSQM);
      if (successStatus != 'success') return this.$message.error(`${successStatus}`); // 不存在PC授权码弹出信息
      const h = this.$createElement;
      this.$msgbox({
        title: '提示',
        message: h('p', { style: 'margin: 10px 0; text-align: center;' }, [
          h('span', null, '请确认新的PC授权码为：'),
          h('span', { style: 'font-size: 14px;font-weight: 600; color: rgb(239, 77, 77)' }, `${this.editPCSQM}？`),
        ]),
        confirmButtonText: '确定修改',
        showCancelButton: true,
        cancelButtonText: '取消',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = '执行中...';
            getChangePcCredentials({
              ids: this.currentPanel.list.editPCSQM_dialog.data.JE_PHONE_PACKAGE_SHOP_ID,
              pcCode: this.editPCSQM,
              userName: this.currentPanel.userInfo.userName,
              userCode: this.currentPanel.userInfo.userId,
              userId: this.currentPanel.userInfo.userId,
            }).then((data) => {
              instance.confirmButtonLoading = false;
              if (!data.success) {
                instance.confirmButtonText = '确认修改';
                this.$message.error(data.message);
                return;
              }
              this.currentPanel.list.getList(); // 重新刷新列表
              this.$message({
                type: 'success',
                message: '已成功修改PC授权码',
              });
              this.currentPanel.list.editPCSQM_dialog.show = false;
              this.editPCSQM = '';
              done();
            });
          } else {
            done();
          }
        },
      });
    },
    /**
   * 校验PC授权码是否规格正确
   * @param {String} pc_code PC授权码
   */
    isPC_SQM(pc_code) {
      const error = {
        '01': '请输入PC授权码',
        '02': 'PC授权码格式有误,格式为：XXXX-XXXX-XXXX-XXXX',
      };
      if (!pc_code) return error['01']; // 不存在PC授权码
      if (pc_code.length != 19) return error['02'];
      const arr = pc_code.split('-');
      if (arr.length != 4) return error['02'];
      let errorMsg = true;
      arr.forEach((str) => {
        if (str.replace(/[^\d]/g, '').length !== 4) {
          errorMsg = false;
        }
      });
      if (!errorMsg) return error['02'];
      return 'success'; // 成功状态  判断通过
    },
  },
};
</script>

<style lang="less" scoped>
@import '../../../../style/shop/util';
.myApp-dialogApp {
  .displayCenter-dialogApp {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

</style>
