/*
 * @Descripttion: 简要描述 ——
 * @Author: Shuangshuang Song
 * @Date: 2020-03-31 11:42:54
 * @LastEditTime: 2021-09-29 16:31:42
 */
import fetch from '@/assets/js/ajax'
import {
  POST_LOAD,
  POST_INVOICE_DETAIL,
  POST_GET_CONTRACT_DETAIL,
  POST_LOAD_WHITE_BAR_ORDER_INFO,
  POST_HISTORY_VERSION,
  POST_HISTORY_UPGRADE_PACKAGE,
  POST_VERSION_DETAILS,
  POST_DOWNLOAD_UPGRADE_PACKAGE,
  POST_GET_BANNER,
  POST_LOAD_MACHINE_INFO,
  POST_EDIT_MACHINE_INFO,
  POST_REMOVE_BLACKLIST,
  POST_EDIT_PHOTO,
  POST_GET_WHITE_BAR_DETAIL,
  POST_FAST_PAYMENTS,
  POST_LOAD_CONTRACT_MODEL,
  POST_EDIT_COPYRIGHT,
  POST_CHECKAUDITSTATUS,
  POST_REJECTINFO,
  POST_BIND_CUSTOMER,
  POST_unBindCustomer,
  POST_Dictionary,
  POST_plan,
  POST_doSave,
  POST_load_work,
  POST_doRemove,
  POST_checkVipService,
  POST_sponsorProcess,
  POST_complete,
  POST_rollback,
  POST_doUpdate,
  POST_viptime,
  POST_project,
  POST_getAppShopInfo,
  POST_APP_doSave,
  POST_APP_doUpdate,
  POST_testConnection,
  POST_getAppProDic,
  POST_getAppdoRemove,
  POST_changePcCredentials,
  POST_SEARCH,
  POST_SEARCHHOME,
  POST_SAVEQUS,
  POST_SELECTDIC,
  POST_ADDONCE
} from './api'

/**
 * @name getLoad
 * @Descripttion: load方法
 * @param {Object}  param
 * @return {}
 */
export function getLoad(param) {
  return fetch({ url: POST_LOAD, data: param })
    .then(result => result.data)
    .catch()
}
/**
 * @name getBindCustomer
 * @Descripttion: 获取蒜瓣客户绑定数据
 * @param {Object}  param
 * @return {}
 */
export function getBindCustomer(param) {
  return fetch({ url: POST_BIND_CUSTOMER, data: param })
    .then(result => result.data)
    .catch()
}
// APP 版本单独下拉字典
export function getAppProDicSelect(param) {
  return fetch({ url: POST_getAppProDic, data: param })
    .then(result => result.data)
    .catch()
}
// APP 版本删除功能
export function getAppdoRemove(param) {
  return fetch({ url: POST_getAppdoRemove, data: param })
    .then(result => result.data)
    .catch()
}
// APPP校验服务器地址
export function getTestConnection(param) {
  return fetch({ url: POST_testConnection, data: param })
    .then(result => result.data)
    .catch()
}
// APP打包导入
export function getAppShopInfo(param) {
  return fetch({ url: POST_getAppShopInfo, data: param })
    .then(result => result.data)
    .catch()
}
// APP打包表单提交
export function getAppDoSave(param) {
  return fetch({ url: POST_APP_doSave, data: param })
    .then(result => result.data)
    .catch()
}
// APP打包修改PC授权码
export function getChangePcCredentials(param) {
  return fetch({ url: POST_changePcCredentials, data: param })
    .then(result => result.data)
    .catch()
}
// APP打包表单修改
export function getAppDoUpdate(param) {
  return fetch({ url: POST_APP_doUpdate, data: param })
    .then(result => result.data)
    .catch()
}
// 解绑蒜客户接口
export function getUnBindCustomer(param) {
  return fetch({ url: POST_unBindCustomer, data: param })
    .then(result => result.data)
    .catch()
}

// 工单的项目名称字典下拉
export function getTaskProject(param) {
  return fetch({ url: POST_project, data: param })
    .then(result => result.data)
    .catch()
}
// 工单的vip记录接口
export function getViptime(param) {
  return fetch({ url: POST_viptime, data: param })
    .then(result => result.data)
    .catch()
}
// 工单提交的修改接口
export function getDoUpdate(param) {
  return fetch({ url: POST_doUpdate, data: param })
    .then(result => result.data)
    .catch()
}
// 工单提交的校验接口
export function getcheckVipService(param) {
  return fetch({ url: POST_checkVipService, data: param })
    .then(result => result.data)
    .catch()
}
// 工单退回验证失败
export function getRollback(param) {
  return fetch({ url: POST_rollback, data: param })
    .then(result => result.data)
    .catch()
}
// 工单送交成功的接口
export function getCompleteTask(param) {
  return fetch({ url: POST_complete, data: param })
    .then(result => result.data)
    .catch()
}
// 工单提交接口
export function getSponsorProcess(param) {
  return fetch({ url: POST_sponsorProcess, data: param })
    .then(result => result.data)
    .catch()
}
// 工单提交获取列表删除事件
export function getDoRemove(param) {
  return fetch({ url: POST_doRemove, data: param })
    .then(result => result.data)
    .catch()
}
// 工单提交获取列表接口
export function getLoadWork(param) {
  return fetch({ url: POST_load_work, data: param })
    .then(result => result.data)
    .catch()
}
// 工单提交保存接口
export function getDoSave(param) {
  return fetch({ url: POST_doSave, data: param })
    .then(result => result.data)
    .catch()
}
// 获取字典接口数据
export function getDictionary(param) {
  return fetch({ url: POST_Dictionary, data: param })
    .then(result => result.data)
    .catch()
}
// 工单计划接口
export function getPlan(param) {
  return fetch({ url: POST_plan, data: param })
    .then(result => result.data)
    .catch()
}
/**
 * @name getRejectInfo
 * @Descripttion: 审核驳回原因
 * @param {Object}  param
 * @return {}
 */
export function getRejectInfo(param) {
  return fetch({ url: POST_REJECTINFO, data: param })
    .then(result => result.data)
    .catch()
}
/**
 * @name getCheckAuditStatus
 * @Descripttion: 修改版权
 * @param {Object}  param
 * @return {}
 */
export function getCheckAuditStatus(param) {
  return fetch({ url: POST_CHECKAUDITSTATUS, data: param })
    .then(result => result.data)
    .catch()
}

/**
 * @name getEditCopyright
 * @Descripttion: 修改版权
 * @param {Object}  param
 * @return {}
 */
export function getEditCopyright(param) {
  return fetch({ url: POST_EDIT_COPYRIGHT, data: param })
    .then(result => result.data)
    .catch()
}

/**
 * @name getInvoiceDetail
 * @Descripttion: 获取发票详情
 * @param {Object}  param
 * @return {}
 */
export function getInvoiceDetail(param) {
  return fetch({ url: POST_INVOICE_DETAIL, data: param })
    .then(result => result.data)
    .catch()
}
/**
 * @name getContractDetail
 * @Descripttion: 获取合同详情
 * @param {Object}  param
 * @return {}
 */
export function getContractDetail(param) {
  return fetch({ url: POST_GET_CONTRACT_DETAIL, data: param })
    .then(result => result.data)
    .catch()
}
/**
 * @name loadContractModel
 * @Descripttion: 获取合同模板
 * @param {Object}  param
 * @return {}
 */
export function loadContractModel(param) {
  return fetch({ url: POST_LOAD_CONTRACT_MODEL, data: param })
    .then(result => result.data)
    .catch()
}
/**
 * @name getwhitebarList
 * @Descripttion: 获取白条消费列表
 * @param {Object}  param
 * @return {}
 */
export function getwhitebarList(param) {
  return fetch({ url: POST_LOAD_WHITE_BAR_ORDER_INFO, data: param })
    .then(result => result.data)
    .catch()
}
/**
 * @name getwhitebarDetail
 * @Descripttion: 获取白条消费基础信息
 * @param {Object}  param
 * @return {}
 */
export function getwhitebarDetail(param) {
  return fetch({ url: POST_GET_WHITE_BAR_DETAIL, data: param })
    .then(result => result.data)
    .catch()
}
/**
 * @name whiteSettlement
 * @Descripttion: 白条结算
 * @param {Object}  param
 * @return {}
 */
export function whiteSettlement(param) {
  return fetch({ url: POST_FAST_PAYMENTS, data: param })
    .then(result => result.data)
    .catch()
}
/**
 * @name getHistoryVersion
 * @Descripttion: 获取历史版本列表
 * @param {Object}  param
 * @return {}
 */
export function getHistoryVersion(param) {
  return fetch({ url: POST_HISTORY_VERSION, data: param })
    .then(result => result.data)
    .catch()
}
/**
 * @name getHistoryPackage
 * @Descripttion: 获取历史升级包
 * @param {Object}  param
 * @return {}
 */
export function getHistoryPackage(param) {
  return fetch({ url: POST_HISTORY_UPGRADE_PACKAGE, data: param })
    .then(result => result.data)
    .catch()
}
/**
 * @name getVesionDeatail
 * @Descripttion: 获取版本详情
 * @param {Object}  param
 * @return {}
 */
export function getVesionDeatail(param) {
  return fetch({ url: POST_VERSION_DETAILS, data: param })
    .then(result => result.data)
    .catch()
}
/**
 * @name downloadPackage
 * @Descripttion: 下载升级包
 * @param {Object}  param
 * @return {}
 */
export function downloadPackage(param) {
  return fetch({ url: POST_DOWNLOAD_UPGRADE_PACKAGE, data: param })
    .then(result => result.data)
    .catch()
}

// 动态获取banner图信息
export function getAdvertising(param) {
  return fetch({ url: POST_GET_BANNER, data: param })
    .then(result => result.data)
    .catch()
}
/**
 * @name loadMachines
 * @Descripttion: 获取商业证书机器使用详情
 * @param {Object}  param
 * @return {}
 */
export function loadMachines(param) {
  return fetch({ url: POST_LOAD_MACHINE_INFO, data: param })
    .then(result => result.data)
    .catch()
}
/**
 * @name addRemark
 * @Descripttion: 获取商业证书机器使用详情 白名单添加备注或者解除绑定
 * @param {Object}  param
 * @return {}
 */
export function addRemark(param) {
  return fetch({ url: POST_EDIT_MACHINE_INFO, data: param })
    .then(result => result.data)
    .catch()
}
/**
 * @name deleteMachine
 * @Descripttion: 获取商业证书机器使用详情 黑名单删除机器
 * @param {Object}  param
 * @return {}
 */
export function deleteMachine(param) {
  return fetch({ url: POST_REMOVE_BLACKLIST, data: param })
    .then(result => result.data)
    .catch()
}
/**
 * @name editPhoto
 * @Descripttion: 获取商业证书机器使用详情 黑名单删除机器
 * @param {Object}  param
 * @return {}
 */
export function editPhoto(param) {
  return fetch({ url: POST_EDIT_PHOTO, data: param })
    .then(result => result.data)
    .catch()
}
/**
 * @name searchQuery
 * @Descripttion: 关键字搜索
 * @param {*} param
 * @returns
 */
export function searchQuery(param) {
  return fetch({ url: POST_SEARCH, data: param })
    .then(result => result.data)
    .catch()
}
/**
 * @name searchHome
 * @Descripttion: 关键字搜索
 * @param {*} param
 * @returns
 */
export function searchHome(param) {
  return fetch({ url: POST_SEARCHHOME, data: param })
    .then(result => result.data)
    .catch()
}
/**
 * @name saveQus
 * @Descripttion: 关键字搜索
 * @param {*} param
 * @returns
 */
export function saveQus(param) {
  return fetch({ url: POST_SAVEQUS, data: param })
    .then(result => result.data)
    .catch()
}

/**
 * @name saveQus
 * @Descripttion: 关键字搜索
 * @param {*} param
 * @returns
 */
export function getDic(param) {
  return fetch({ url: POST_SELECTDIC, data: param })
    .then(result => result.data)
    .catch()
}
/**
 * @name addOnce
 * @Descripttion: 增加搜索次数
 * @param {*} param
 * @returns
 */
export function addOnce(param) {
  return fetch({ url: POST_ADDONCE, data: param })
    .then(result => result.data)
    .catch()
}
