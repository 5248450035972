/*
 * @Descripttion:
 * @Author: 张明尧
 * @Date: 2020-12-07 15:37:24
 * @LastEditTime: 2020-12-22 09:13:53
 */
import { getStorage } from '@/util/util';
import Form from './form.js';
import List from './list.js';

export default class Current {
  constructor(vm) {
    this.form = null; // 当前的表单数据
    this.vm = vm || ''; // 当前的vue实例对象
    this.userInfo = JSON.parse(getStorage('local', 'userInfo'));
    this.init();
  }

  // 当前主面板的初始化事件
  init() {
    this.init_form(); // 初始化表单信息
    this.init_list();
  }

  // 初始化表单信息
  init_form() {
    this.form = new Form(this.vm);
  }

  // 初始化列表信息
  init_list() {
    this.list = new List(this.vm, this.userInfo);
  }
}
