<!--
 * @Descripttion:
 * @Author: 张明尧
 * @Date: 2020-12-17 10:35:30
 * @LastEditTime: 2021-01-05 15:55:17
-->
<template>
  <!-- 模拟上传方法，主动触发上传 -->
  <div>
    <el-upload
      :show-file-list="false"
      :data="uploadData"
      :headers="uploadFileObj.header"
      :multiple="false"
      :on-success="handleAvatarSuccess"
      :before-upload="beforeAvatarUpload"
      :action="uploadFileObj.serveUrl"
      accept="image/png,image/jpg,image/jpeg"
      class="myApp-upload"
    />
    <el-upload
      :show-file-list="false"
      :data="uploadData"
      :headers="uploadFileObj.header"
      :multiple="false"
      :on-success="handleAvatarSuccess"
      :before-upload="beforeAvatarUpload_file"
      :action="uploadFileObj.serveUrl"
      class="myApp-upload-file"
    />
  </div>
</template>

<script>
export default {
  props: {
    uploadFileObj: {
      type: Object,
      default: () => ({}),
    },
    currentPanel: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      uploadData: {},
    };
  },
  methods: {
    handleAvatarSuccess(res, file) {
      this.$emit('uploadSuccess', res.obj[0]);
    },
    // 文件上传
    beforeAvatarUpload_file(file) {
      // 判断文件上传的尾缀是否是自定义的尾缀内容
      if (this.uploadFileObj.fileType.length > 0) {
        const arr = file.name.split('.');
        if (!this.uploadFileObj.fileType.includes(arr[arr.length - 1])) {
          this.$message.error(`上传文件的后缀只能是 ${this.uploadFileObj.fileType.join(',')} 格式!`);
          return false;
        }
      }
      this.currentPanel.form.formLoading = true;
      this.uploadData.files = file;
    },
    // 图标上传显示
    beforeAvatarUpload(file) {
      const _this = this;
      const isJPG = file.type === 'image/png';
      if (!isJPG) {
        this.$message.error('上传应用图标只能是 PNG 格式!');
        return false;
      }
      this.currentPanel.form.formLoading = true;
      const isSize = new Promise((resolve, reject) => {
        const width = 1024; // 限制图片尺寸为1024
        const height = 1024;
        const _URL = window.URL || window.webkitURL;
        const img = new Image();
        img.onload = function () {
          const valid = img.width === width && img.height === height;
          valid ? resolve() : reject();
        };
        img.src = _URL.createObjectURL(file);
      }).then(() => file, () => {
        this.currentPanel.form.formLoading = false;
        _this.$message.error('图片尺寸限制为1024 x 1024');
        return Promise.reject();
      });
      this.currentPanel.form.formLoading = true;
      this.uploadData.files = file;
      return isSize;
    },
  },
};
</script>

<style>

</style>
