/*
 * @Descripttion:
 * @Author: 张明尧
 * @Date: 2020-12-07 15:39:40
 * @LastEditTime: 2021-01-08 14:32:45
 */
import {
  getAppShopInfo, getAppdoRemove,
} from '@/pages/index/actions/actions';
import Search from './search';

export default class List {
  constructor(vm, userInfo) {
    this.vm = vm; // 当前vue的实例对象
    this.list = []; // 当前列表数据
    this.editPCSQM_dialog = {
      show: false,
      data: {},
    }; // 修改PC授权码弹框
    this.CopySearchlist = []; // 当前全部数据列表---用于过滤前端本地搜索
    this.listLoading = false; // 获取列表数据的加载状态
    this.DB_dialog = false; // 打包次数过多提示弹框
    this.userInfo = userInfo; // 当前的缓存用户
    this.search = new Search(vm, userInfo); // 当前过滤的条件
    this.clickList = {}; // 当前点击的列表数据临081
    this.stepsObj = {
      status: '',
      type: '01',
      DBZT: 'WDB',
    };
    this.init();
  }

  init() {
    // this.init_mock();
    this.getList(); // 获取列表数据
  }

  /**
   * 获取列表数据
   */
  getList() {
    this.listLoading = true;
    getAppShopInfo({ customerId: this.userInfo.customerId }).then((res) => {
      this.listLoading = false;
      if (!res.success) return;
      this.list = res.obj.data.map((data, index) => this.filterList(data, index));
      this.isShowRecordFunc(); // 判断是否显示下拉更多记录
      this.CopySearchlist = JSON.parse(JSON.stringify(this.list));
    });
  }

  /**
   * 删除操作
   * @param {Object} list 被删除的数据
   */
  removeList(list) {
    if (!list.JE_PHONE_PACKAGE_RECORD_ID) return;
    this.vm.$confirm('此操作将删除该记录, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }).then(() => {
      getAppdoRemove({ tableCode: 'JE_PHONE_PACKAGE_RECORD', ids: list.JE_PHONE_PACKAGE_RECORD_ID }).then((res) => {
        // debugger;
        if (!res.success) return;
        this.getList(); // 重新获取最新的数据
        this.vm.$message({
          type: 'success',
          message: '已删除',
        });
      });
    });
  }

  /**
   * 获得搜索和查询后的数据
   */
  getSearchList() {
    const keyword = this.search.keyWord; // 关键词
    const selectword = this.search.AppSelectText;
    const ARRs = JSON.parse(JSON.stringify(this.CopySearchlist));
    // debugger;
    // 根据关键词进行过滤
    this.list = ARRs.filter((mapList) => {
      mapList.record.arr = mapList.record.arr.filter((record) => {
        // 如果没有关键词和下拉筛选
        if (selectword.DICTIONARYITEM_ITEMCODE == 'all' && !keyword) {
          return record;
        }
        // 如果只有关键词
        if (selectword.DICTIONARYITEM_ITEMCODE == 'all' && keyword) {
          if (record.RECORD_APP_CODE.includes(keyword) || record.RECORD_APP_MC.includes(keyword)) return record;
        }
        // 如果有下拉没有关键词
        if (selectword.DICTIONARYITEM_ITEMCODE != 'all' && !keyword) {
          if (record.RECORD_STATE_CODE == selectword.DICTIONARYITEM_ITEMCODE) return record;
        }
        // 如果有下拉状态和关键词
        if (keyword) {
          if (record.RECORD_APP_MC.includes(keyword) || record.RECORD_APP_CODE.includes(keyword)) {
            if (record.RECORD_STATE_CODE == selectword.DICTIONARYITEM_ITEMCODE) return record;
          }
        }
      });
      // 如果筛选完毕有数据的话就显示
      mapList.record.arr.length > 0 && (mapList.isShowRecord = true) || (mapList.isShowRecord = false);
      return mapList;
    });
  }

  /**
   * 打包申请
   * @param {Object} obj 当前列表点击的数据
   */
  list_DBSQ(obj) {
    this.clickList = obj;
  }

  /**
   * 过滤list数据格式
   * @param {Object} data 当前接口拿到的数据
   * @param {String} index 当前list数据的下标
   */
  // eslint-disable-next-line class-methods-use-this
  filterList(data, index) {
    const obj = {
      record: {
        show: data.child.length > 0,
        arr: this.filterChild(data.child),
      },
      PC_SQM: data.SHOP_PC_SYZS || '未绑定',
      APP_SQM: data.SHOP_APP_SYZS || '未绑定',
      APP_USE: `${data.SHOP_YYDBCS}/${data.SHOP_DBCS}`,
      APPID: {
        status: data.SHOP_APP_CODE,
        name: data.SHOP_APP_CODE || '未绑定',
      },
      ApplyFor: data.SHOP_DBCS - data.SHOP_YYDBCS > 0,
      APP_NAME: {
        status: data.SHOP_APP_MC,
        name: data.SHOP_APP_MC || '未绑定',
      },
      isShowRecord: false, // 默认第一条数据展开历史的打包记录
    };
    return Object.assign(obj, data);
  }

  /**
   * 当前循环的每条的列表数据判断是否显示展开打包记录
   */
  isShowRecordFunc() {
    this.list = this.list.filter((val) => {
      if (this.list.findIndex(lis => lis.isShowRecord) < 0 && val.child.length > 0) {
        val.isShowRecord = true;
      }
      return val;
    });
  }

  /**
   * 过滤列表的子数据
   * @param {Array} arr 列表的子数据
   */
  // eslint-disable-next-line class-methods-use-this
  filterChild(arr) {
    const zt = {
      WDB: {
        name: '未打包',
        code: 'WDB',
        color: '#979797',
      },
      CG: {
        name: '成功',
        code: 'CG',
        color: '#288C4E',
      },
      SB: {
        name: '失败',
        code: 'SB',
        color: '#EF4D4D',
      },
      JXZ: {
        name: '进行中',
        code: 'JXZ',
        color: '#979797',
      },
    };
    return arr.map(res => ({
      APP_NAME: res.RECORD_APP_MC,
      APPPID: res.RECORD_APP_CODE,
      DJ_TIME: res.SY_CREATETIME,
      APP_TYPE: res.RECORD_TYPE_CODE.split(','),
      APP_DB: zt[res.RECORD_DBZT],
      STATUS: {
        code: res.RECORD_STATE_CODE,
        name: res.RECORD_STATE_NAME,
        color: {
          '01': {
            color: '#979797',
          },
          '02': {
            color: '#3c78d8',
          },
          '03': {
            color: '#38761d',
          },
          '04': {
            color: '#cc0000',
          },
          '05': {
            color: '',
          },
        },
      },
      SET: {
        '01': [
          {
            name: '修改',
            code: 'edit',
          },
          {
            name: '删除',
            code: 'remove',
          },
        ],
        '02': false,
        '03': [
          {
            name: '下载',
            code: 'download',
          },
        ],
        '04': [
          {
            name: '重新修改',
            code: 'edit',
          },
        ],
        '05': false,
      },
      JE_PHONE_PACKAGE_RECORD_ID: res.JE_PHONE_PACKAGE_RECORD_ID || '',
      ...res,
    }));
  }
}
