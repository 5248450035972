/*
 * @Descripttion:
 * @Author: 张明尧
 * @Date: 2020-12-07 16:00:44
 * @LastEditTime: 2020-12-28 10:22:24
 */
import {
  getDictionary,
} from '@/pages/index/actions/actions';

export default class Search {
  constructor(vm, userInfo) {
    this.limit = 10;
    this.vm = vm;
    this.keyWord = ''; // 关键词
    this.AppSelect = []; // 下拉菜单
    this.jquery = null;
    this.AppSelectText = {
      DICTIONARYITEM_ITEMCODE: 'all',
      DICTIONARYITEM_ITEMNAME: '全部',
    };
    this.userInfo = userInfo; // 用户信息
    this.init();
  }

  init() {
    this.getSelectDic(); // 获取下拉字典
  }

  /**
   * 获取快速查询的下拉字典
   */
  getSelectDic() {
    getDictionary({ codes: 'VIP_COPYING_STATE', userId: this.userInfo.userId }).then((res) => {
      if (!res.success) return;
      this.AppSelect = [{
        DICTIONARYITEM_ITEMCODE: 'all',
        DICTIONARYITEM_ITEMNAME: '全部',
      }, ...res.obj.VIP_COPYING_STATE];
    });
  }

  /**
   * 关键词搜索
   * @param {String} val 关键词
   */
  keyWordChange(val) {
    this.keyWord = val;
    this.vm.currentPanel.list.getSearchList();
  }

  /**
   * 点击下拉选中的数据
   * @param {Object} data 当前下拉的字典信息
   */
  AppOnSelect(data) {
    this.AppSelectText = {
      DICTIONARYITEM_ITEMCODE: data.DICTIONARYITEM_ITEMCODE,
      DICTIONARYITEM_ITEMNAME: data.DICTIONARYITEM_ITEMNAME,
    };
    this.vm.currentPanel.list.getSearchList();
  }

  get_upload_jq() {
    const params = {};
    // 如果只存在快速查询
    if (this.AppSelectText.DICTIONARYITEM_ITEMCODE != 'all' && !this.keyWord) {
      params.group = [
        {
          type: 'and',
          value: [
            {
              code: 'RECORD_STATE_CODE',
              type: 'in',
              value: [this.AppSelectText.DICTIONARYITEM_ITEMCODE],
            },
          ],
        },
      ];
    }
  }

  // 清空关键词搜索
  clearKeyWord() {
    this.keyWord = ''; // 清空
  }
}
