/*
 * @Descripttion:
 * @Author: 张明尧
 * @Date: 2020-12-07 16:25:22
 * @LastEditTime: 2021-01-05 15:47:15
 */
import {
  getDictionary,
} from '@/pages/index/actions/actions';

const FORM_TYPE = {
  // 客户信息
  KHXX: [
    {
      zd: 'V_JEPAAS_VIP_KHGL',
      RECORD_KHMC: '',
      key: 'RECORD_KHMC',
      name: '客户名称',
      type: 'input',
      required: true, // 是否必填
      // eslint-disable-next-line no-use-before-define
      rule: obj => myAPP_util.from_rule.requireRule(obj), // 判断权限
    },
    {
      key: 'RECORD_PCSQM',
      name: 'PC授权码',
      type: 'input',
      required: true, // 是否必填
      rule: obj => myAPP_util.from_rule.requireRule(obj), // 判断权限
    },
    {
      key: 'RECORD_APPSQM',
      name: 'APP授权码',
      type: 'input',
    },
  ],
  // 基本信息
  JBXX: [
    {
      key: 'RECORD_APP_MC',
      RECORD_APP_MC: '',
      name: 'APP名称',
      type: 'input',
      required: true, // 是否必填
      rule: obj => myAPP_util.from_rule.requireRule(obj), // 判断权限
    },
    {
      key: 'RECORD_APP_CODE',
      RECORD_APP_CODE: '',
      name: 'APPID',
      type: 'input',
      required: true, // 是否必填
      rule: obj => myAPP_util.from_rule.requireRule(obj), // 判断权限
    },
    {
      key: 'RECORD_FWQDZ',
      RECORD_FWQDZ: '',
      name: '服务器地址',
      type: 'input',
      required: true, // 是否必填
      rule: obj => myAPP_util.from_rule.requireRule(obj), // 判断权限
    },
    {
      key: 'RECORD_APP_PACKAGENAME',
      name: 'APP包名',
      RECORD_APP_PACKAGENAME: 'com.je.app',
      readOnly: true, // 只读
      type: 'input',
      required: true, // 是否必填
      rule: obj => myAPP_util.from_rule.requireRule(obj), // 判断权限
    },
    {
      key: 'RECORD_YYTB',
      RECORD_YYTB: '',
      name: '应用图标',
      type: 'input',
      upload: {
        status: true,
        class: 'myApp-upload',
      }, // 上传文件
      readOnly: true,
      cursor: true,
      suffixIcon: 'jeicon jeicon-folders',
      required: true, // 是否必填
      rule: obj => myAPP_util.from_rule.requireRule(obj), // 判断权限
    },
    {
      key: 'RECORD_VERSION',
      RECORD_VERSION: '',
      zd: 'VIP_PRODUCT_TYOE_TREE',
      otherType: true, // 其他格式获取该字典的值
      name: '版本',
      type: 'select',
      arrs: [],
      multiple: false,
      required: true, // 是否必填
    },
    {
      RECORD_TYPE_CODE: [],
      key: 'RECORD_TYPE_CODE',
      zd: 'APP_SYSTEM_TYPE',
      name: 'APP类型',
      span: 24,
      required: true, // 是否必填
      multiple: true,
      type: 'select',
      arrs: [],
      secondHtml: [
        {
          title: 'IOS证书',
          title_fontSize: '18px',
          code: ['IOS'],
          title_href: '',
          isShow: false, // 根据条件是否选择
          secondArr: [
            {
              key: 'RECORD_ZS_PROFILE_WJ',
              RECORD_ZS_PROFILE_WJ: '',
              name: '证书_PROFILE_文件',
              type: 'input',
              upload: {
                status: true,
                class: 'myApp-upload-file',
              }, // 上传文件
              readOnly: true,
              requiredFileType: ['mobileprovision'], // 要求文件的证书
              cursor: true,
              suffixIcon: 'jeicon jeicon-folders',
              required: true, // 是否必填
              rule: obj => myAPP_util.from_rule.requireRule(obj), // 判断权限
            },
            {
              key: 'RECORD_ZSSY',
              RECORD_ZSSY: '',
              name: '证书私钥',
              type: 'input',
              upload: {
                status: true,
                class: 'myApp-upload-file',
              }, // 上传文件
              readOnly: true,
              requiredFileType: ['p12'], // 要求文件的证书私钥
              cursor: true,
              suffixIcon: 'jeicon jeicon-folders',
              required: true, // 是否必填
              rule: obj => myAPP_util.from_rule.requireRule(obj), // 判断权限
            },
            {
              key: 'RECORD_ZSMM',
              RECORD_ZSMM: '',
              name: '证书密码',
              placeholder: '自动化打包仅支持证书私钥密码为123456',
              required: true, // 是否必填
              rule: obj => myAPP_util.from_rule.requireRule(obj), // 判断权限
              type: 'input',
            },
          ],
        },
      ],
    },
  ],
  // SDK配置
  SDKPZ: [
    {
      RECORD_SDK_CODE: [],
      key: 'RECORD_SDK_CODE',
      zd: 'JEPAAS_APP_SDK',
      span: 24,
      name: '启用SDK',
      hint: true, // 是否有提示的黄子信息
      type: 'select',
      multiple: true,
      arrs: [],
      secondHtml: [
        {
          title: '微信配置：登录鉴权，分享;',
          code: ['Share'],
          title_href: '<a href="https://open.weixin.qq.com" target="_black">微信开放平台</a>',
          isShow: false, // 根据条件是否选择
          secondArr: [
            {
              key: 'RECORD_WX_APPID',
              RECORD_WX_APPID: '',
              name: '微信_APPID',
              required: true, // 是否必填
              rule: obj => myAPP_util.from_rule.requireRule(obj), // 判断权限
              type: 'input',
            },
            {
              key: 'RECORD_WX_APPSECRET',
              RECORD_WX_APPSECRET: '',
              name: '微信_APPSECRET',
              required: true, // 是否必填
              rule: obj => myAPP_util.from_rule.requireRule(obj), // 判断权限
              type: 'input',
            },
            {
              key: 'RECORD_WX_IOS',
              RECORD_WX_IOS: '',
              name: '登录鉴权_微信_地址',
              required: true, // 是否必填
              rule: obj => myAPP_util.from_rule.requireRule(obj), // 判断权限
              type: 'input',
            },
          ],
        },
        {
          title: 'QQ配置：登录鉴权，分享;',
          code: ['Share'],
          title_href: '<a href="http://open.qq.com" target="_black">腾讯开放平台</a>',
          isShow: false,
          secondArr: [
            {
              key: 'RECORD_QQ_APPID',
              RECORD_QQ_APPID: '',
              name: 'QQ_APPID',
              type: 'input',
              required: true, // 是否必填
              rule: obj => myAPP_util.from_rule.requireRule(obj), // 判断权限
            },
          ],
        },
        {
          title: '个推配置：消息推送;',
          code: ['Push'],
          title_href: '<a href="https://dev.getui.com/dev" target="_black">个推放平台</a>',
          isShow: false, // 根据条件是否选择
          secondArr: [
            {
              key: 'RECORD_GT_APPID',
              RECORD_GT_APPID: '',
              name: '个推_APPID',
              required: true, // 是否必填
              rule: obj => myAPP_util.from_rule.requireRule(obj), // 判断权限
              type: 'input',
            },
            {
              key: 'RECORD_GT_APPKEY',
              RECORD_GT_APPKEY: '',
              name: '个推_APPKEY',
              required: true, // 是否必填
              rule: obj => myAPP_util.from_rule.requireRule(obj), // 判断权限
              type: 'input',
            },
            {
              key: 'RECORD_GT_APPSECRET',
              RECORD_GT_APPSECRET: '',
              name: '个推_APPSECRET',
              required: true, // 是否必填
              rule: obj => myAPP_util.from_rule.requireRule(obj), // 判断权限
              type: 'input',
            },
          ],
        },
        {
          title: '支付配置：支付;',
          code: ['Payment'],
          title_href: '<a href="http://open.qq.com" target="_black">腾讯开放平台</a>',
          isShow: false, // 根据条件是否选择
          secondArr: [
            {
              key: 'RECORD_ZF_ZFBZF',
              RECORD_ZF_ZFBZF: '',
              name: '支付_微信支付',
              required: true, // 是否必填
              rule: obj => myAPP_util.from_rule.requireRule(obj), // 判断权限
              type: 'input',
            },
            {
              key: 'RECORD_ZF_WXZF_APPID',
              RECORD_ZF_WXZF_APPID: '',
              name: '支付_微信_APPID',
              required: true, // 是否必填
              rule: obj => myAPP_util.from_rule.requireRule(obj), // 判断权限
              type: 'input',
            },
            {
              key: 'RECORD_ZF_WXZF_IOS',
              RECORD_ZF_WXZF_IOS: '',
              name: '支付_微信_IOS',
              required: true, // 是否必填
              rule: obj => myAPP_util.from_rule.requireRule(obj), // 判断权限
              type: 'input',
            },
          ],
        },
        {
          key: 'RECORD_SDK_CODE',
          RECORD_SDK_CODE: '',
          title: '地图配置：地图，定位;',
          code: ['Maps'],
          title_href: '<a href="https://ask.dcloud.net.cn/article/29" target="_black">地图配置指南</a>',
          isShow: false,
          secondArr: [
            {
              key: 'RECORD_DTLX',
              zd: 'JE_PHONE_PACKAGE_MAPS',
              RECORD_DTLX: '',
              name: '地图类型',
              required: true, // 是否必填
              multiple: false,
              type: 'select',
              arrs: [],
              html: {},
            },
            {
              key: 'RECORD_DT_APPKEY_IOS',
              RECORD_DT_APPKEY_IOS: '',
              name: '地图_APPKEY_IOS',
              type: 'input',
              required: true, // 是否必填
              rule: obj => myAPP_util.from_rule.requireRule(obj), // 判断权限
            },
            {
              key: 'RECORD_DT_APPKEY_ANDROID',
              RECORD_DT_APPKEY_ANDROID: '',
              name: 'APPKEY_ANDROID',
              type: 'input',
              required: true, // 是否必填
              rule: obj => myAPP_util.from_rule.requireRule(obj), // 判断权限
            },
          ],
        },
      ],
    },
  ],
  // 打包信息
  DBXX: [
    {
      key: 'RECORD_XZ_H5',
      name: 'H5下载',
      type: 'input',
    },
    {
      key: 'RECORD_XZ_IOS',
      name: 'IOS下载',
      type: 'input',
    },
    {
      key: 'RECORD_XZ_AZ',
      name: '安卓下载',
      type: 'input',
    },
  ],
  // 系统信息
  XTXX: [
    {
      key: 'SY_CREATETIME',
      name: '登记时间',
      type: 'input',
    },
    {
      key: 'SY_CREATEUSERNAME',
      name: '登记人',
      type: 'input',
    },
  ],
};

const myAPP_util = {
  // 初始化表单所需要的数据格式
// arr: 根据后台页面表单的数据传递的数据
  filterAppForm(key) {
    if (!key) return;
    const arr = []; // 返回的数据格式
    FORM_TYPE[key].forEach((res) => {
      const obj = {
        html: this.form_type_change(res.type, res),
      };
      // 如果有字典信息将字典字段保存
      if (res.secondHtml) {
        res.secondHtml.forEach((secondHtml) => {
          secondHtml.isShow = false; // 初始化的时候默认隐藏
          secondHtml.secondArr.forEach((secondArr) => {
            secondArr.html = this.form_type_change(secondArr.type, secondArr);
          });
        });
      }
      obj[key] = '';
      arr.push({ ...res, ...obj });
    });
    return arr;
  },

  // 根据掺入的type格式动态分配
  form_type_change(type, data) {
    if (type == 'input') return this.form_input(data); // 输入框代码
    if (type == 'select') return this.from_select(data); // 输入框代码
  },
  // 输入框的时候过滤数据
  form_input(data) {
    return {
      label: data.name, // 输入框名字
      placeholder: `请输入${data.name}`,
    };
  },
  /**
   * 下拉框的默认参数HTML
   * @param {Object} data 上层type动态传递的内容
   */
  from_select(data) {
    const defaultVal = {
      codes: [],
      val: [],
    }; // 当前选中的数据
    data.arrs.forEach((res) => {
      if (res.default) {
        defaultVal.codes = [...defaultVal.codes, ...res.codes];
        defaultVal.val.push(JSON.stringify(res));
      }
    });
    if (defaultVal.val.length > 0) data[data.key] = defaultVal.val;
    data.secondHtml && data.secondHtml.filter((sec) => {
      if (defaultVal.codes.includes(sec.code)) sec.isShow = true;
      return sec;
    });
    return {
      label: data.name,
      arrs: data.arrs,
      multiple: data.multiple || false,
    };
  },
  // 规则判断
  from_rule: {
    /**
     * 默认必须填写规则
     * @param {String} val 当前输入框的内容
     * @param {String} name 当前输入的名字
     * @param {Object} vm Vue的实例对象
     */
    requireRule({ val, name, vm }) {
      if (!val) {
        vm.$message.error(`请输入${name}`);
        return false;
      }
      return true;
    },
  },
};

export default myAPP_util;
