<!--
 * @Descripttion:
 * @Author: 张明尧
 * @Date: 2020-12-07 15:32:19
 * @LastEditTime: 2021-01-05 15:48:05
-->
<template>
  <div>
    <Loading :loadingData="{isShow: currentPanel.form.formLoading}" />
    <div class="myApp-form">
      <steps :stepsObj="currentPanel.list.stepsObj" />
      <div
        v-for="(item, index_form) in currentPanel.form.formDatas"
        :key="index_form"
      >
        <h4 class="font-size-20">
          {{ item.title }}
        </h4>
        <el-row>
          <el-col
            v-for="(datas, index_every) in item.dataArrs"
            :key="index_every"
            :span="datas.span && datas.span || 12"
            class="margin-top-10"
          >
            <el-col
              :span="datas.span && 3 || 6"
              style="height: 40px;line-height: 40px"
              class="font-size-14 text-status-right"
            >
              <span
                :class="datas.required && 'mustFill'"
              >
                {{ datas.name }}
              </span>
            </el-col>
            <el-col
              v-if="datas.type == 'input'"
              :span="datas.span && 21 || 18"
              class="padding-left-20"
              style="height: 40px;line-height: 35px"
            >
              <el-input
                v-if="!datas.upload || !datas.upload.status"
                :readonly="datas.readOnly || currentPanel.form.formReadOnly"
                @input="formInputChange(datas, item.dataArrs)"
                :class="[datas.required && !datas[datas.key] && 'rule_error_input', datas.cursor && 'cursor-pointer-input']"
                @blur="datas.required && datas.rule({val: datas[datas.key], name: datas.name, vm: currentPanel.form.vm})"
                v-model="datas[datas.key]"
                :placeholder="datas.html.placeholder || ''"
              />
              <div
                @click="()=>{inputFileUp(datas)}"
                v-else
                :style="datas[datas.key] && 'border-color: #DCDFE6'"
                :class="!currentPanel.form.formReadOnly && 'cursor-pointer-hover'"
                class="div_el_input"
              >
                <span
                  v-if="!datas[datas.key]"
                  style="color: #C0C4CC"
                >1024*1024尺寸图片</span>
                <span
                  v-else
                  style="color: #3f3f3f"
                >{{ datas[datas.key].split('*')[0] }}</span>
                <i :class="datas.suffixIcon" />
              </div>
            </el-col>
            <el-col
              v-if="datas.type == 'select'"
              :span="datas.span && 21 || 18"
              class="padding-left-20"
              style="height: auto;line-height: 35px"
            >
              <el-select
                :disabled="currentPanel.form.formReadOnly"
                :class="datas.required && !datas[datas.key] && 'rule_error_input'"
                :multiple="datas.html.multiple"
                v-model="datas[datas.key]"
                :placeholder="`请选择${datas.name || ''}`"
                @change="((val)=>{form_select(val, datas)})"
                clearable
              >
                <el-option
                  v-for="(selectItem, index_select) in datas.arrs"
                  :key="index_select"
                  :label="selectItem.DICTIONARYITEM_ITEMNAME"
                  :value="JSON.stringify(selectItem)"
                />
              </el-select>
            </el-col>
            <!-- 提示显示 -->
            <el-col
              v-if="datas.hint"
              :span="24"
            >
              <div class="hint_myApp">
                启用SDK后，如果没有配置对应的SDK配置，SDK将不可用；
                <a
                  href="https://ask.dcloud.net.cn/docs/"
                  target="_black"
                >HBuilder官方文档</a>
              </div>
              <!-- <div class="hint_myApp">
                  警告：unipush需要联系客服人员进行特殊打包
                </div> -->
            </el-col>
            <el-col
              :span="24"
              v-if="datas.secondHtml"
            >
              <el-col
                :span="24"
                v-for="(datas_second, index_datas_second) in datas.secondHtml"
                :key="index_datas_second"
              >
                <el-col
                  v-if="datas_second.isShow"
                  :span="24"
                >
                  <el-col
                    :span="22"
                    :offset="2"
                    :style="datas_second.title_fontSize && `font-size: ${datas_second.title_fontSize}`"
                    class="padding-left-20 font-size-16 font-weight-600 margin-top-40 margin-bottom-20"
                  >
                    <span>{{ datas_second.title }} </span>
                    <span
                      v-html="datas_second.title_href"
                      style="color: #2440b3"
                      class="cursor-pointer-hover-315EFB text-decoration-underline"
                    />
                  </el-col>
                  <el-col
                    v-for="(datas_secondArr, index_secondArr) in datas_second.secondArr"
                    :key="`${index_secondArr}secondArr`"
                    :span="12"
                    class="margin-top-10"
                  >
                    <el-col
                      :span="6"
                      style="height: 40px;line-height: 40px"
                      class="font-size-14 text-status-right"
                    >
                      <span
                        :class="datas_secondArr.required && 'mustFill'"
                      >
                        <span>{{ datas_secondArr.name }}</span>
                      </span>
                    </el-col>
                    <el-col
                      v-if="datas_secondArr.type == 'input'"
                      :span="18"
                      class="padding-left-20"
                      style="height: 40px;line-height: 35px"
                    >
                      <el-input
                        :readonly="datas_secondArr.readOnly || currentPanel.form.formReadOnly"
                        v-if="!datas_secondArr.upload || !datas_secondArr.upload.status"
                        v-model="datas_secondArr[datas_secondArr.key]"
                        :placeholder="datas_secondArr.placeholder && datas_secondArr.placeholder || `请输入${datas_secondArr.name || ''}`"
                        :class="datas_secondArr.required &&!datas_secondArr[datas_secondArr.key] && 'rule_error_input'"
                        @blur="datas_secondArr.required && datas_secondArr.rule({val: datas_secondArr[datas_secondArr.key], name: datas_secondArr.name, vm: currentPanel.form.vm})"
                      />
                      <div
                        @click="()=>{inputFileUp_file(datas_secondArr)}"
                        v-else
                        :style="datas_secondArr[datas_secondArr.key] && 'border-color: #DCDFE6'"
                        :class="!currentPanel.form.formReadOnly && 'cursor-pointer-hover'"
                        class="div_el_input"
                      >
                        <span
                          v-if="!datas_secondArr[datas_secondArr.key]"
                          style="color: #C0C4CC"
                        >
                          <span v-if="datas_secondArr.placeholder">{{ datas_secondArr.placeholder }}</span>
                          <span v-else>请上传{{ datas_secondArr.name }} </span>
                        </span>
                        <span
                          v-else
                          style="color: #3f3f3f"
                        >{{ datas_secondArr[datas_secondArr.key].split('*')[0] }}</span>
                        <i :class="datas_secondArr.suffixIcon" />
                      </div>
                    </el-col>
                    <el-col
                      v-if="datas_secondArr.type == 'select'"
                      :span="18"
                      class="padding-left-20"
                      style="height: auto;line-height: 35px"
                    >
                      <el-select
                        :disabled="currentPanel.form.formReadOnly"
                        @change="((val)=>{form_select(val, datas_secondArr)})"
                        :class="datas_secondArr.required &&!datas_secondArr[datas_secondArr.key] && 'rule_error_input'"
                        v-model="datas_secondArr[datas_secondArr.key]"
                        :placeholder="`请输入${datas_secondArr.name || ''}`"
                      >
                        <el-option
                          v-for="(selectItem_secondArr, index_select_secondArr) in datas_secondArr.arrs"
                          :key="index_select_secondArr"
                          :label="selectItem_secondArr.DICTIONARYITEM_ITEMNAME"
                          :value="JSON.stringify(selectItem_secondArr)"
                        />
                      </el-select>
                    </el-col>
                  </el-col>
                </el-col>
              </el-col>
            </el-col>
          </el-col>
        </el-row>
      </div>
      <uploadFile
        :currentPanel="currentPanel"
        :uploadFileObj="uploadFileObj"
        @uploadSuccess="uploadSuccess"
      />
    </div>
    <div
      v-if="['01', '04'].includes(currentPanel.list.stepsObj.type)"
      style="bottom: 20px;
    width: 100%;"
      class="text-status-center margin-top-40 position-absolute"
    >
      <el-button
        @click="save()"
        style="background: #F19B2C; color: #fff; border-color: #F19B2C;"
      >
        保存
      </el-button>
      <el-button
        @click="submit()"
        class="margin-left-20"
        style="background: #EF4D4D; color: #fff; border-color: #EF4D4D;"
      >
        保存并提交
      </el-button>
    </div>
  </div>
</template>

<script>
import uploadFile from '@/pages/index/views/my/myApp/components/uploadFile';
import steps from '@/pages/index/views/my/myApp/components/steps';
import { getToken } from '@/util/auth';

export default {
  components: {
    uploadFile,
    steps,
  },
  props: {
    currentPanel: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      // 上传文件的所需参数
      uploadFileObj: {
        serveUrl: '/jepaas/document/file',
        header: {
          jepaasAuthorization: getToken(),
          'Platform-Agent': 'AppleWebKit/537.36 (KHTML, like Gecko)',
        },
        fileType: [], // 当前点击的文件上传的输入框的key
      },
      uploadInput: {}, // 当前上传的文件数据
    };
  },
  methods: {
    // 下拉选择事件
    form_select(val, datas) {
      if (datas.type == 'select') {
        let val_arr = [];
        if (datas.multiple) {
          if (val.length > 0) {
            val.forEach((res) => {
              const json_data = JSON.parse(res);
              json_data.DICTIONARYITEM_ITEMCODE && (val_arr = [...val_arr, json_data.DICTIONARYITEM_ITEMCODE]);
              this.currentPanel.form.form_select_show(val_arr, datas.secondHtml);
            });
          } else {
            datas.secondHtml.forEach((res) => {
              res.isShow = false;
            });
          }
        } else {
          datas[datas.key] = val;
        }
      }
    },
    // 单独对包名进行处理
    formInputChange(data, arr) {
      if (data.key != 'RECORD_APP_CODE') return;
      arr = arr.map((res) => {
        if (res.key == 'RECORD_APP_PACKAGENAME') {
          const obj = {
            '2019-1018-1000-3102': 'com.je.jeapp4paas2',
            '2019-0102-1347-8233': 'com.je.suanbanyun2',
          };
          res.RECORD_APP_PACKAGENAME = obj[data.RECORD_APP_CODE] || `com.je.app${data.RECORD_APP_CODE.replace(/-/g, '')}`;
        }
        return res;
      });
    },
    // 上传文件的
    inputFileUp_file(data) {
      if (!data.upload.status || this.currentPanel.form.formReadOnly) return;
      data.requiredFileType && (this.uploadFileObj.fileType = data.requiredFileType) || (this.uploadFileObj.fileType = []);
      this.uploadInput = data;
      document.querySelector('.myApp-upload-file input').click();
    },
    // 上传的数据
    inputFileUp(data) {
      // 如果不是附件上传的input
      if (!data.upload.status || this.currentPanel.form.formReadOnly) return;
      this.uploadInput = data;
      document.querySelector('.myApp-upload input').click();
    },
    // 上传成功
    uploadSuccess(file) {
      this.uploadInput[this.uploadInput.key] = `${file.relName}*${file.fileKey}`;
      this.uploadInput.fileKey = file.fileKey;
      this.currentPanel.form.formLoading = false;
    },
    // 保存表单
    save() {
      this.currentPanel.form.save_form();
    },
    // 提交表单
    submit() {
      this.currentPanel.form.save_form('sub');
    },
  },
};
</script>

<style lang="less" scoped>
@import '../../../../style/shop/util';
.myApp-form {
  width: 70%;
  margin: 0 auto;
  height: 820px;
  overflow: auto;
  .displayHeightCenter {
    display: flex;
    align-items: center;
  }
  .hint_myApp {
    background-color: #fdf6ec;
    color: #e6a23c;
    border-radius: 4px;
    padding: 0 16px;
    height: 40px;
    line-height: 40px;
    font-size: 12px;
    margin-top: 10px;
  }
  .div_el_input {
    height: 40px;
    line-height: 40px;
    border: 1px solid #f74444;
    border-radius: 5px;
    padding-left: 15px;
    font-size: 14px;
    position: relative;
    &:hover {
      i {
        color: #8e8e8f;
      }
    }
    i {
      position: absolute;
      right: 15px;
      color: #bfc1c7;
    }
  }
}
</style>
