<!--
 * @Descripttion:
 * @Author: 张明尧
 * @Date: 2020-12-07 15:08:19
 * @LastEditTime: 2021-01-05 09:31:09
-->
<template>
  <div
    class="Jepaas-myApp background-FFFFFF"
  >
    <el-backtop />
    <div>
      <div class="box_top">
        <Header />
      </div>
    </div>
    <div class="je-detail-content">
      <div class="font-size-30 color-3F3F3F font-weight-600">
        我的APP
      </div>
      <div class="position-relative">
        <el-dialog
          :close-on-click-modal="false"
          :visible.sync="currentPanel.form.show"
          title="打包申请"
          width="100%"
          custom-class="useExplainApp_Task"
        >
          <Form :currentPanel="currentPanel" />
        </el-dialog>
      </div>
      <div>
        <Search :currentPanel="currentPanel" />
        <List :currentPanel="currentPanel" />
      </div>
    </div>
    <!-- 不是VIP用户 -->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="VIP_CODE_show"
      width="40%"
      custom-class="useExplain_Task"
    >
      <div
        slot="title"
        class="useTitle"
      >
        VIP服务提示
      </div>
      <div
        style="line-height: 2"
        class="color-3F3F3F font-size-18 padding-bottom-30 padding-LR-20 padding-top-10"
      >
        <div class="text-status-center font-size-20 font-weight-600">
          您当前没有绑定VIP客户码，<span
            @click="VIP_CODE_showGo"
            style="color: #F9514F"
            class="cursor-pointer-hover-F9514F"
          >请先前往绑定客户码!</span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Header from '@/components/Header';
import Search from './components/search.vue';
import List from './components/list.vue';
import Form from './components/form.vue';
import Current from './class/current.js';

export default {
  components: {
    Header,
    List,
    Form,
    Search,
  },
  data() {
    return {
      CurrentJs: new Current(this),
      VIP_CODE_show: false,
    };
  },
  computed: {
    currentPanel() {
      const currentPanel = this.CurrentJs;
      return currentPanel;
    },
  },
  created() {
    this.isVIP_judge();
  },
  methods: {
    isVIP_judge() {
      // !this.currentPanel.userInfo.customerId && (this.VIP_CODE_show = true);
    },
    VIP_CODE_showGo() {
      this.$router.push({
        path: '/modifyInfo',
      });
    },
  },
};
</script>
<style lang="less">
@import './style/index.less';
.Jepaas-myApp {
  .useExplainApp_Task {
    height: 100%;
    padding: 0!important;
    max-height: 100%;
    max-width: 100%;
    .el-dialog__title {
      font-size: 20px;
      font-weight: 600;
    }
    .el-dialog__headerbtn .el-dialog__close {
      font-size: 28px;
    }
    .el-dialog__body {
      padding: 0;
    }
  }
  .useExplain_Task {
    width: 640px !important;
    border-radius: 20px !important;
    padding: 0!important;
    .vipCount_main_bottom {
      padding-bottom: 20px;
      border-bottom: 1px dashed;
    }
    .vipCount_main {
      display: flex;
      justify-content: space-around;
      .every_vip_time {
        display: flex;
        align-items: center;
      }
      .vipCount {
        width: 30px;
        height: 30px;
        line-height: 30px;
        border-radius: 50%;
        text-align: center;
        color: #fff;
        margin-left: 10px;
      }
    }
    .el-dialog__header {
      padding: 0!important;
    }
    .useTitle {
      display: block;
      font-weight: 600;
      font-size: 20px;
      padding: 20px;
      box-sizing: border-box;
      color: #ffffff;
      height: 80px;
      background: url('../../../../detail/assets/images/zs/service.png') !important;
      background-size: 100% 100% !important;
      background-repeat: no-repeat !important;
    }
    .el-dialog__headerbtn .el-icon-close{
      font-size: 25px;
      color: #ffffff!important;
    }
    .el-dialog__title {
      font-size: 20px !important;
      font-weight: 600 !important;
    }
    .el-dialog__body {
      font-size: 20px;
      font-weight: 400;
      color: rgba(63, 63, 63, 1);
      padding-top: 10px;
      padding-bottom: 0;
    }
  }
}

</style>
<style lang="less" scoped>
@import '../../../style/shop/util';
.Jepaas-myApp {
  min-height: 100vh;
  position: relative;
  width: 100%;
  .page_box {
    z-index: 2000!important;
  }
  .el-backtop {
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    z-index: 10;
    border-radius: 4px;
    width: 52px;
    height: 52px;
    right: 2% !important;
    bottom: 43px !important;
    font-size: 24px;
    color: #ff3041;
    &:hover {
      background: #ff3041;
      > i {
        color: #ffffff;
      }
    }
  }
  .box_top {
    width: 100%;
    height: 80px;
    background: linear-gradient(
      270deg,
      rgba(252, 103, 81, 1) 0%,
      rgba(246, 71, 77, 1) 100%
    );
  }
  .je-detail-content {
    margin: 0 auto;
    padding: 20px 0;
    width: 1250px;
    overflow: hidden;
    .elemnt-input-YGDD .el-input__inner {
      border: none;
      border-radius: 20px;
    }
    .title {
      height: 42px;
      font-size: 30px;
      font-weight: 600;
      color: rgba(63, 63, 63, 1);
      line-height: 42px;
    }
    .question {
      height: 70px;
      background: rgba(255, 242, 240, 1);
      margin-top: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 26px;
      font-weight: 500;
      .el-button {
        font-size: 26px;
        margin-left: 10px;
      }
    }
    .explains {
      margin-top: 30px;
      .el-tabs__item {
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 20px;
        font-weight: 400;
        color: rgba(63, 63, 63, 1);
        height: initial;
        line-height: normal;
      }
      .el-tabs__header {
        margin-bottom: 30px;
      }
      .el-tabs__active-bar {
        background-color: #f84e4e;
      }
    }
  }
}
</style>
