<!--
 * @Descripttion:
 * @Author: 张明尧
 * @Date: 2020-12-17 16:31:37
 * @LastEditTime: 2021-01-05 16:43:48
-->
<template>
  <div class="myApp_steps">
    <div class="main font-size-20">
      <div
        v-for="(item, index) in defaultArr"
        :key="index"
        :style="`width:${100 / defaultArr.length}%`"
        class="text-status-center"
      >
        <div class="display-center position-relative">
          <div class="steps_div display-center">
            <div
              :style="`background:${item.show && item.color || stepsStyle.color}; border-color: ${item.show && item.color || stepsStyle.color}`"
              class="steps_circle"
            />
            <div
              v-if="index > 0"
              :style="`background:${item.show && item.color || stepsStyle.color}; border-color: ${item.show && item.color || stepsStyle.color}`"
              class="xian"
            />
          </div>
        </div>
        <div class="margin-top-10 font-size-16">
          <div
            :style="`color:${item.color || stepsStyle.color};`"
            class="color-3F3F3F"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    stepsObj: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      defaultArr: [
        {
          name: '未提交',
          code: 'WTJ',
          type: '01',
          show: true,
          index: 0,
        },
        {
          name: '审核中',
          code: 'SHZ',
          type: '02',
          show: false,
          index: 1,
        },
        {
          name: '已审核',
          code: 'YSH',
          type: '03',
          show: false, // 默认不显示
          index: 2,
        },
      ],
      ARR: [
        {
          name: '未提交',
          code: 'WTJ',
          type: '01',
          show: true,
          index: 0,
        },
        {
          name: '审核中',
          code: 'SHZ',
          type: '02',
          show: true,
          index: 1,
        },
        {
          name: '已审核',
          code: 'YSH',
          type: '03',
          show: true, // 默认不显示
          index: 2,
        },
        {
          name: '已驳回',
          code: 'YBH',
          type: '04',
          show: false, // 默认不显示
          index: 2,
        },
        {
          name: '已失效',
          code: 'YSX',
          type: '05',
          show: false, // 默认不显示
          index: 2,
        },
      ],
      stepsStyleArr: {
        YBH: {
          color: '#EF4D4D',
        },
        YSH: {
          color: 'rgb(78 187 119)',
        },
        YSX: {
          color: '#EF4D4D',
        },
      },
      db_status: {
        WDB: {
          name: '未打包',
          code: 'WDB',
          show: true,
          color: '#979797',
          index: 3,
        },
        CG: {
          name: '打包成功',
          code: 'CG',
          show: true,
          color: 'rgb(78 187 119)',
          index: 3,
        },
        SB: {
          name: '打包失败',
          code: 'SB',
          show: true,
          color: '#EF4D4D',
          index: 3,
        },
        JXZ: {
          name: '正在打包',
          code: 'JXZ',
          show: true,
          index: 3,
          color: '#979797',
        },
      },
      stepsStyle: {
        color: '#979797',
      },
    };
  },
  watch: {
    stepsObj: {
      deep: true,
      handler(newVal) {
        this.getStepsInit();
      },
    },

  },
  created() {
    this.getStepsInit();
  },
  methods: {
    init_step() {
      this.defaultArr = [
        {
          name: '未提交',
          code: 'WTJ',
          type: '01',
          show: true,
          index: 0,
        },
        {
          name: '审核中',
          code: 'SHZ',
          type: '02',
          show: false,
          index: 1,
        },
        {
          name: '已审核',
          code: 'YSH',
          type: '03',
          show: false, // 默认不显示
          index: 2,
        },
      ];
      this.stepsStyle = {
        color: '#979797',
      };
    },
    getStepsInit() {
      if (!this.stepsObj.type) return;
      this.init_step();
      const arr = this.ARR.filter(res => res.index == 2);
      if (arr.findIndex(res => res.type == this.stepsObj.type) < 0) {
        this.defaultArr.filter((code) => {
          if (code.type == this.stepsObj.type) {
            code.show = true;
          }
          if (code.show && this.stepsObj.type != '01') {
            code.color = 'rgb(78 187 119)';
          }
          return code;
        });

        return;
      } // 如果不是第三层的话 直接返回
      const steps = [];
      this.ARR.forEach((res) => {
        res.index == 2 && (res.show = false); // 先将所有的最后一级改为隐藏
        if (res.type == this.stepsObj.type) {
          res.show = true;
          this.stepsStyle = this.stepsStyleArr[res.code];
        }
        if (res.show) steps.push(res);
      });
      this.defaultArr = steps;
      this.getDB_steps();
    },
    // 判读当前是否进入打包状态
    getDB_steps() {
      if (this.stepsObj.type == '03') {
        this.defaultArr.push(this.db_status[this.stepsObj.DBZT]);
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import '../../../../style/shop/util';
.myApp_steps {
  width: 60%;
  margin: 0 auto;
  .display-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .main {
    display: flex;
    justify-content: center;
    align-items: center;
    .steps_div {
      width: 30px;
      height: 30px;
      .xian {
        position: absolute;
        width: 60%;
        left: -30%;
        height: 1px;
        background: #D8D8D8;
        border-radius: 2px;
        border: 1px solid #D8D8D8;
      }
      .steps_circle {
        width: 80%;
        height: 80%;
        border-radius: 50%;
        border: 3px solid #979797;
      }
    }
  }
}
</style>
