<!--
 * @Descripttion:
 * @Author: 张明尧
 * @Date: 2020-12-07 15:32:12
 * @LastEditTime: 2021-01-11 11:00:38
-->
<template>
  <div class="margin-top-20 myApp-list position-relative">
    <Loading :loadingData="{isShow: currentPanel.list.listLoading}" />
    <el-row
      class="listTitle color-3F3F3F font-size-18 font-weight-600 text-status-center"
    >
      <el-col
        v-for="(title, index) in titles"
        :key="index"
        :span="title.span"
        :class="title.class"
      >
        {{ title.name }}
      </el-col>
    </el-row>
    <el-row
      v-for="(list, _list) in currentPanel.list.list"
      :key="_list"
      class="listMain text-status-center font-size-16"
    >
      <el-col
        :span="24"
        class="main"
      >
        <el-col :span="4">
          <span
            @click="PC_SQM_Edit(list)"
            class="PC_SQM_class"
          >{{ list.PC_SQM }} <i class="jeicon jeicon-automatic-pen font-size-16" /></span>
        </el-col>
        <el-col :span="4">
          {{ list.APP_SQM }}
        </el-col>
        <el-col
          :style="!list.APPID.status && 'color: #A9ABA9'"
          :span="4"
        >
          {{ list.APPID.name }}
        </el-col>
        <el-col
          :style="!list.APP_NAME.status && 'color: #A9ABA9'"
          :span="5"
        >
          {{ list.APP_NAME.name }}
        </el-col>
        <el-col
          :span="3"
          class="text-status-center"
        >
          {{ list.APP_USE }}
        </el-col>
        <el-col
          :span="4"
          class="text-status-center cursor-pointer-hover"
        >
          <span
            @click="list_DBSQ(list)"
            style="color: #6A9CFF"
          > 打包申请 </span>
          <span
            v-if="list.SHOP_APPSQMWJ"
            @click="list_license(list)"
            style="color: #6A9CFF"
          > 下载license </span>
        </el-col>
      </el-col>
      <el-col :span="24">
        <div
          v-if="list.record.show"
          style="color: #8D98AB"
          class="text-status-center font-size-14 padding-bottom-10"
        >
          <span
            @click="recordChange(list)"
            class="cursor-pointer-hover"
          >
            {{ list.isShowRecord ? '收起APP打包记录': '展开APP打包记录' }}
            <i
              :class="list.isShowRecord ? 'jeicon-arrow-up-two' : 'jeicon-arrow-down-two'"
              class="jeicon  font-size-14"
            />
          </span>
        </div>
      </el-col>
      <el-col
        :span="24"
        v-if="list.isShowRecord"
      >
        <div
          style="background: #FAFAFA"
        >
          <el-row
            class="color-3F3F3F padding-LR-10 padding-top-10"
          >
            <el-col
              v-for="(record, record_index) in recordList"
              :key="record_index"
              :span="record.span"
              class="color-A9ABA9 font-size-16"
            >
              <span>{{ record.name }}</span>
            </el-col>
          </el-row>
          <div>
            <el-row
              v-for="(more, more_index) in list.record.arr"
              :key="more_index"
              class="font-size-14"
            >
              <el-col
                :span="24"
                class="recordMain"
              >
                <el-col
                  :span="3"
                  style="color: #6A9CFF"
                  class="cursor-pointer-hover font-weight-600"
                >
                  <span @click="listCheck(more, list)">{{ more.APP_NAME }}</span>
                </el-col>
                <el-col :span="4">
                  {{ more.APPPID }}
                </el-col>
                <el-col
                  :style="`color: ${more.STATUS.color[more.STATUS.code].color}`"
                  :span="3"
                >
                  {{ more.STATUS.name }}
                </el-col>
                <el-col :span="3">
                  <span :style="`color: ${more.APP_DB.color}`">{{ more.APP_DB.name }}</span>
                </el-col>
                <el-col :span="4">
                  <span
                    v-for="(app, _app_index) in more.APP_TYPE"
                    :key="_app_index"
                  >{{ app }} <span v-if="_app_index < more.APP_TYPE.length -1">,</span></span>
                </el-col>
                <el-col :span="4">
                  {{ more.DJ_TIME }}
                </el-col>
                <el-col
                  :span="3"
                  class="position-relative"
                >
                  <span
                    v-if="more.SET"
                    class="cursor-pointer-hover"
                    style="color: #6A9CFF"
                  >
                    <span
                      v-for="(set, set_index) in more.SET[more.STATUS.code]"
                      :key="set_index"
                    >
                      <span @click="listCheck(more, list, set.code)"> {{ set.code != 'download' && set.name || '' }} </span>
                      <el-dropdown
                        @command="(type)=>{ downloadMyList(type,more) }"
                        v-if="more.SET && more.RECORD_DBZT == 'CG' && set.code == 'download' && !['SB'].includes(more.RECORD_DBZT) "
                      >
                        <span
                          class="cursor-pointer-hover font-size-14"
                          style="color: #6A9CFF"
                        >
                          下载
                        </span>
                        <el-dropdown-menu
                          slot="dropdown"
                        >
                          <el-dropdown-item
                            :command="appType"
                            v-for="(appType, _appType) in more.APP_TYPE"
                            :key="_appType"
                          >
                            {{ appType }}
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </span>
                  </span>
                </el-col>
              </el-col>
            </el-row>
            <div
              v-if="currentPanel.list.list.length != 0 && list.record.arr.length == 0"
              style="min-height: 200px"
              class="displayFlexCenter"
            >
              <div
                style="display:table-cell;vertical-align:middle;text-align: center"
              >
                <img
                  src="../../../../../detail/assets/images/alreadyIndent/nullData.png"
                  alt
                >
                <div class="color-BEBEBE">
                  暂无数据
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <div
      v-if="currentPanel.list.list.length == 0"
      class="displayFlexCenter"
    >
      <div
        style="display:table-cell;vertical-align:middle;text-align: center"
      >
        <img
          src="../../../../../detail/assets/images/alreadyIndent/nullData.png"
          alt
        >
        <div class="color-BEBEBE">
          如果购买了JEAPP，请在售后群里联系客服人员，生成APP证书
        </div>
      </div>
    </div>
    <dialogApp :currentPanel="currentPanel" />
  </div>
</template>

<script>
import dialogApp from '@/pages/index/views/my/myApp/components/dialogApp';

export default {
  components: {
    dialogApp,
  },
  props: {
    currentPanel: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      titles: [
        {
          name: 'PC授权码',
          span: 4,
        },
        {
          name: 'APP授权码',
          span: 4,
        },
        {
          name: 'APPID',
          span: 4,
        },
        {
          name: 'APP名称',
          span: 5,
        },
        {
          name: '使用次数',
          span: 3,
          class: 'text-status-center',
        },
        {
          name: '操作',
          span: 4,
          class: 'text-status-center',
        },
      ],
      recordList: [
        {
          name: 'APP名称',
          span: 3,
        },
        {
          name: 'APPID',
          span: 4,
        },
        {
          name: '状态',
          span: 3,
        },
        {
          name: '打包状态',
          span: 3,
        },
        {
          name: 'APP类型',
          span: 4,
        },
        {
          name: '登记时间',
          span: 4,
        },
        {
          name: '操作',
          span: 3,
        },
      ],
    };
  },
  methods: {
    recordChange(list) {
      list.isShowRecord = !list.isShowRecord;
    },
    // 点击列表名字查看
    listCheck(data, list, code) {
      if (code && code == 'remove') {
        this.currentPanel.list.removeList(data);
        return;
      }
      this.currentPanel.list.stepsObj.type = data.RECORD_STATE_CODE;
      this.currentPanel.list.stepsObj.DBZT = data.RECORD_DBZT || 'WDB';
      this.currentPanel.form.listCheck = Object.assign(data, { SHOP_DBCS: list.SHOP_DBCS || '', SHOP_YYDBCS: list.SHOP_YYDBCS || '' }); // 当前列表点击打包申请的数据
      // 如果是已审核或者审核中的状态让全表单禁止修改
      if (['02', '03'].includes(data.RECORD_STATE_CODE)) {
        this.currentPanel.form.formReadOnly = true;
      } else {
        this.currentPanel.form.formReadOnly = false;
      }
      this.currentPanel.form.form_post_update = true; // 当前是更新接口
      this.currentPanel.form.fromBackShow(data);
    },
    /**
     * 点击打包申请
     */
    list_DBSQ(list) {
      // 如果打包次数超过限定打包次数
      if (list.SHOP_YYDBCS >= list.SHOP_DBCS) {
        this.currentPanel.list.DB_dialog = true;
        return;
      }
      this.currentPanel.form.formReadOnly = false; // 关闭只读的问题
      this.currentPanel.form.form_post_update = false; // 当前是提交接口
      this.currentPanel.form.listCheck = list; // 当前列表点击打包申请的数据
      this.currentPanel.list.stepsObj.status = 'WTJ';
      this.currentPanel.list.stepsObj.DBZT = 'WDB';
      this.currentPanel.list.stepsObj.type = '01';
      if (list.record && list.record.arr && list.record.arr.length > 0) {
        this.currentPanel.form.fromBackShow(list.record.arr[0]);
      } else {
        this.currentPanel.form.init();
        this.currentPanel.form.isShow(true); // 显示form表单抽屉
      }
    },
    // 点击下载list_license
    list_license(data) {
      if (!data.SHOP_APPSQMWJ) return;
      window.open(`/jepaas/document/down?fileKey=${data.SHOP_APPSQMWJ.split('*')[1]}`);
    },
    // 点击修改PC授权码
    PC_SQM_Edit(list) {
      // PC授权码弹出框
      this.currentPanel.list.editPCSQM_dialog = {
        show: true,
        data: list,
      };
    },
    // 点击下载
    downloadMyList(type, data) {
      const typeKey = {
        Android: 'RECORD_XZ_AZ',
        H5: 'RECORD_XZ_H5',
        IOS: 'RECORD_XZ_IOS',
      };
      const file = data[typeKey[type]];
      if (!file) return this.$message.warning('下载文件已损坏!');
      window.open(`/jepaas/document/down?fileKey=${file.split('*')[1]}`);
    },
  },
};
</script>
<style lang="less">
.myApp-list {
  .el-dropdown {
    height: 30px;
  }
}
</style>
<style lang="less" scoped>
@import '../../../../style/shop/util';
.myApp-list {
  min-height: 500px;
  .PC_SQM_class {
    color: #2440b3;
    // text-decoration:underline;
    cursor: pointer;
  }
  .displayFlexCenter {
    // background: #fbfbfb;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 500px;
  }
  .listTitle {
    height: 50px;
    line-height: 50px;
    padding:0 10px;
    background-color: #F5F5F5
  }
  .listMain {
    background: #ffffff;
    // padding-bottom: 10px;
    border: 1px solid #F5F5F5;
    border-top: none;
    .main {
      height: 80px;
      line-height: 80px;
      padding: 0 10px;
    }
    .recordMain {
      height: 60px;
      line-height: 60px;
      padding: 0 10px;
    }
  }
  @keyframes openRecord {
    0% {height: 0px;}
    100% {height: 150px;}
  }
  @keyframes closeRecord {
    0% {height: 150px;}
    100% {height: 0;}
  }
  .recordContentClose {
    overflow: hidden;
    height: 0;
    animation: closeRecord 0.5s alternate;
    animation-fill-mode: forwards;
    animation-direction: alternate;
  }
  .recordContentOpen {
    height: 150px;
    animation: openRecord 1s alternate;
  }
}
</style>
