/*
 * @Descripttion:
 * @Author: 张明尧
 * @Date: 2020-12-07 15:40:20
 * @LastEditTime: 2021-01-06 10:49:59
 */
import {
  getDictionary, getAppDoSave, getTestConnection, getAppProDicSelect, getAppDoUpdate,
} from '@/pages/index/actions/actions';
import { getStorage } from '@/util/util';
import myAPP_util from '../util/util_form';


export default class Form {
  constructor(vm) {
    this.vm = vm; // 当前实例vue对象
    this.formLoading = false; // form的区域加载
    this.serve_dialog = false; // 服务器地址调用弹框
    this.formReadOnly = false; // 点击查看表单的时候的只读
    this.DB_lengthDialog = {
      show: false,
      number: 0,
    }; // 服务器打包次数提示
    this.form_post_update = false; // 是否是要调用更新列表接口
    this.serve_data = {}; // 当前保存需要格式化的数据
    this.defaut_zd = ['Contact', 'VideoPlayer', 'Fingerprint', 'Bluetooth', 'Geolocation', 'Speech']; // 默认显示的下拉字典数据
    this.listCheck = {}; // 当前列表点击的数据传递到form
    this.userInfo = JSON.parse(getStorage('local', 'userInfo'));
    this.show = false; // 是否显示当前的表单
    this.formDatas = {}; // 页面渲染的数据
    this.ruleStatus = true; // 当前表单的正则判断是否全部通过(默认是通过的，点击保存可能出现false的情况，只有为true的时候才会关闭表单)
    this.init();
  }

  // 初始化form
  init() {
    this.init_form();
    this.init_zd_formData();
  }

  // 是否显示当前的表单
  isShow(show = false) {
    this.show = show;
  }

  // 初始化form页面的内容
  init_form() {
    this.formDatas = {
      JBXX: {
        title: '基本信息',
        isShow: true, // 显示
        dataArrs: myAPP_util.filterAppForm('JBXX'), // 数据
      },
      SDKPZ: {
        title: 'SDK配置',
        isShow: true, // 显示
        dataArrs: myAPP_util.filterAppForm('SDKPZ'), // 数据
      },
    };
  }

  // 表单中下拉框选中判断否显示动态下拉
  // eslint-disable-next-line class-methods-use-this
  form_select_show(val, arr) {
    if (val && val.length > 0) {
      arr = arr.filter((res) => {
        res.isShow = false;
        return res;
      });
      arr = arr.filter((res) => {
        res.code.forEach((co) => {
          if (val.includes(co)) {
            res.isShow = true;
          }
        });
        return res;
      });
    } else {
      arr = arr.filter(res => res.isShow = false);
    }
  }

  // 根据字典给form表单赋值下拉菜单
  init_zd_formData() {
    const zds = [];
    const dicArr = {};
    Object.keys(this.formDatas).forEach((formkey) => {
      this.formDatas[formkey].dataArrs.forEach((res) => {
        if (res.zd && !res.otherType) {
          dicArr[res.zd] = res;
          zds.push(res.zd);
        } else if (res.zd && res.otherType) {
          // 如果是版本的下拉菜单就进行单独处理
          if (res.zd == 'VIP_PRODUCT_TYOE_TREE') {
            getAppProDicSelect().then((ddd) => {
              res.html.arrs = res.arrs = ddd.obj.children.map(child => ({
                DICTIONARYITEM_ITEMCODE: child.code,
                DICTIONARYITEM_ITEMNAME: child.name,
              }));
              res[res.key] = JSON.stringify(res.arrs[0]); // 版本默认值为当前最高的
            });
          }
        }
        if (res.secondHtml && res.secondHtml.length > 0) {
          res.secondHtml.forEach((secondHtml) => {
            secondHtml.secondArr && secondHtml.secondArr.forEach((second) => {
              if (second.zd) {
                dicArr[second.zd] = second;
                zds.push(second.zd);
              }
            });
          });
        }
      });
    });

    getDictionary({ codes: zds.join(','), userId: this.userInfo.userId }).then((dic) => {
      Object.keys(dic.obj).forEach((formkey) => {
        dicArr[formkey][dicArr[formkey].key] = [];
        dic.obj[formkey].forEach((dic_default) => {
          if (this.defaut_zd.includes(dic_default.DICTIONARYITEM_ITEMCODE)) {
            dicArr[formkey][dicArr[formkey].key].push(JSON.stringify(dic_default));
            dicArr[formkey].secondHtml && dicArr[formkey].secondHtml.filter((sec) => {
              if (sec.code.includes(dic_default.DICTIONARYITEM_ITEMCODE)) sec.isShow = true;
              return sec;
            });
          }
        });
        dicArr[formkey].arrs = dic.obj[formkey];
        dicArr[formkey].html.arrs = dic.obj[formkey];
      });
    });
  }

  // 根据默认显示的字典数据，进行赋值给下拉
  form_defaultDict() {
  }

  /**
   * 触发保存或者提交
   * @param {String} status 是否提交
   */
  save_form(status) {
    this.serve_data = {}; // 每次点击保存默认初始化保存或者提交的数据
    const defaultData = {
      RECORD_DBZT: 'WDB', // 打包状态
      CRM_YXGL_CUSTOMER_ID: this.userInfo.customerId,
      RECORD_SCYHMC: this.userInfo.customerName,
      RECORD_SCYHBH: this.userInfo.customerId,
      RECORD_SCYHID: this.userInfo.userId,
      RECORD_YYSB_KDXF: 1, // 语音识别，默认为使用科大讯飞
      RECORD_KHMC: this.userInfo.customerName,
      RECORD_PCSQM: this.listCheck.PC_SQM, // PC授权码
      JE_PHONE_PACKAGE_RECORD_ID: this.listCheck.JE_PHONE_PACKAGE_RECORD_ID || '', // 子健Id
      JE_PHONE_PACKAGE_SHOP_ID: this.listCheck.JE_PHONE_PACKAGE_SHOP_ID, // 主键Id
      // 是否提交
      RECORD_STATE_CODE: status && '02' || '01',
      RECORD_STATE_NAME: status && '审核中' || '未提交',
      // 百度地图
      RECORD_DTLX: 'BAIDU_MAP',
      RECORD_DT_APPKEY_ANDROID: 'd9ztrgG2VlEYlrnu5AqH0DcNRkPFdVYe',
      RECORD_DT_APPKEY_IOS: 'bSBOcnnjOvtWz11QQDY3chX0dNqXWKbw',
      // 微信配置：登录鉴权，分享
      RECORD_WX_APPID: 'wxe54a3810da3e994c',
      RECORD_WX_APPSECRET: 'bc2a90f40154eb6d3d0cfd26c27561c0',
      // QQ配置：登录鉴权，分享
      RECORD_QQ_APPID: '101857846',
      // 个推信息
      RECORD_GT_APPID: 'L5R2e0pb7U6ZSEv4Xk6MR3',
      RECORD_GT_APPKEY: 'sgUV75QBo79Qmc8gSyKwe4',
      RECORD_GT_APPSECRET: 'lG0K0tHAbR7fSnct3zLyT9',
      // 登录鉴权_微信_地址
      RECORD_WX_IOS: 'https://suanbanyun.com/',
      RECORD_ZF_WXZF_IOS: 'https://suanbanyun.com/',
    };
    const data = this.filter_post_form(); // 过滤当前的数据
    if (!data) return;
    // 将数组中的数据格式化
    Object.keys(data).forEach((formkey) => {
      // 循环接口数据  判断是否是数组格式
      if (['RECORD_DTLX', 'RECORD_VERSION'].includes(formkey)) {
        data[formkey] = JSON.parse(data[formkey]).DICTIONARYITEM_ITEMCODE;
      }
      if (Array.isArray(data[formkey]) && data[formkey].length > 0) {
        data[formkey] = data[formkey].map((res) => {
          const obj = JSON.parse(res);
          return obj.DICTIONARYITEM_ITEMCODE;
        });
        data[formkey] = data[formkey].join(',');
      }
    });
    // 校验服务器地址是否有效
    const severUrl = data.RECORD_FWQDZ || ''; // 服务器地址
    const appId = data.RECORD_APP_CODE || ''; // APPID
    this.formLoading = true; // 区域加载开启
    return getTestConnection({ url: severUrl, appId }).then((serve) => {
      this.formLoading = false; // 区域加载关闭
      // 如果服务器地址校验失败则弹框提示
      this.serve_data = Object.assign(defaultData, data);
      if (!serve.success) {
        this.serve_dialog = true;
        return;
      }
      // 如果是点击的保存直接调用接口，不是的话弹框提示消耗打包次数的弹框
      if (!status) {
        // 如果是保存的话就不关闭弹框
        this.form_save_post('save');
      } else {
        this.DB_lengthDialog.show = true;
      }
    });
  }

  /**
   * 保存数据接口
   * @param {String} save 如果是点击的表单保存按钮，不关闭弹框
   */
  form_save_post(save) {
    this.formLoading = true; // 区域加载开启
    if (this.form_post_update) {
      return getAppDoUpdate(this.serve_data).then((po) => {
        this.formLoading = false; // 区域加载关闭
        if (!po.success) {
          return this.vm.$message.error(`${po.message}`);
        }
        this.vm.$message.success('信息修改成功');
        // 关闭当前的展示框  是否清空当前的表单数据
        this.vm.currentPanel.list.getList();
        !save && (this.show = false);
      });
    }
    return getAppDoSave(this.serve_data).then((po) => {
      this.formLoading = false; // 区域加载关闭
      if (!po.success) {
        return this.vm.$message.error(`${po.message}`);
      }
      this.vm.$message.success('信息保存成功');
      // 关闭当前的展示框  是否清空当前的表单数据
      this.vm.currentPanel.list.getList();
      !save && (this.show = false);
    });
  }

  /**
   * 当前正则判断失败
   * @param {String} message 提示失败的信息
   */
  rule_error(message) {
    this.ruleStatus = false;
    this.vm.$message.error(message);
  }

  // 将当前的数据过滤生成接口使用的数据
  filter_post_form() {
    let postObject = {};
    // 将第一层对象进行循环
    try {
      Object.keys(this.formDatas).forEach((formkey) => {
        this.formDatas[formkey].dataArrs.forEach((data) => {
          if (data.key) {
            if (data.required && !data[data.key]) {
              this.rule_error(`请输入${data.name}`);
              throw new Error('error');
            }
            if (data.type == 'select' && data[data.key].length <= 0) {
              this.rule_error(`请选择${data.name}`);
              throw new Error('error');
            }
            postObject[data.key] = data[data.key];
          }
          if (data.secondHtml && data.secondHtml.length > 0) {
            // sdk的动态配置的显示隐藏的菜单数据
            data.secondHtml.forEach((second) => {
              if (second.isShow) {
                second.secondArr.forEach((arr) => {
                  if (arr.required && !arr[arr.key]) {
                    this.rule_error(`请输入${arr.name}`);
                    throw new Error('error');
                  }
                  if (arr.type == 'select' && arr[arr.key].length <= 0) {
                    this.rule_error(`请选择${arr.name}`);
                    throw new Error('error');
                  }
                  postObject[arr.key] = arr[arr.key];
                });
              }
            });
          }
        });
      });
    } catch (e) {
      postObject = false;
    }
    return postObject; // 接口所需要参数
  }

  /**
   *  form循环赋值
   * @param {Object} fromData 当前列表的默认数据
   * @param {Object} data 列表上携带过来需要回显的数据
   */
  // eslint-disable-next-line class-methods-use-this
  formDataFZ(fromData, data) {
    if (fromData.type == 'input') {
      fromData[fromData.key] = data[fromData.key] || ''; // 进行赋值
    }
    // 下拉框根据element组件所需的参数赋值
    if (fromData.type == 'select') {
      const selectVal = [];
      fromData.arrs.forEach((fromMap) => {
        const arrCode = data[fromData.key] && data[fromData.key].split(',');
        if (arrCode && arrCode.includes(fromMap.DICTIONARYITEM_ITEMCODE)) {
          selectVal.push(JSON.stringify(fromMap));
        }
      });
      selectVal.length > 0 && (fromData[fromData.key] = selectVal);
      // 单独处理地图和版本的单选情况
      if (['RECORD_DTLX', 'RECORD_VERSION'].includes(fromData.key)) {
        // eslint-disable-next-line prefer-destructuring
        fromData[fromData.key] = selectVal[0];
      }
    }
  }

  /**
   * 点击查看或者修改要返显的数据
   * @param {Object} data 返显示的数据
   */
  fromBackShow(data) {
    Object.keys(this.formDatas).forEach((formkey) => {
      this.formDatas[formkey].dataArrs.forEach((fromData) => {
        // 进行回显赋值操作
        this.formDataFZ(fromData, data);
        // 如果存在根据选中内容动态显示的数据判断
        if (fromData.secondHtml && fromData.secondHtml.length > 0) {
          // sdk的动态配置的显示隐藏的菜单数据
          fromData.secondHtml.forEach((second) => {
            const arrCode = data[fromData.key] && data[fromData.key].split(',') || [];
            // 循环默认列表中的code
            second.code.forEach((secondCode) => {
              if (arrCode && arrCode.includes(secondCode)) {
                second.isShow = true;
                second.secondArr && second.secondArr.forEach((secondHtmlData) => {
                  // 进行回显赋值操作
                  this.formDataFZ(secondHtmlData, data);
                });
              } else {
                // 如果拿到数据没有显示子功能，就隐藏掉显示
                second.isShow = false;
                // 如果没有值的话就将值全部清空
                second.secondArr.forEach((secondHtmlData) => {
                  secondHtmlData[secondHtmlData.key] = '';
                });
              }
            });
          });
        }
      });
    });
    this.isShow(true); // 显示表单
  }

  // 表单的附件上传
  form_uploadFile() {

  }
}
