<!--
 * @Descripttion:
 * @Author: 张明尧
 * @Date: 2020-12-14 14:05:12
 * @LastEditTime: 2021-01-06 15:16:59
-->
<template>
  <div>
    <el-row
      class="margin-top-40"
    >
      <el-col :span="4">
        <!-- 快速查询的菜单 -->
        <div class="padding-left-20">
          <el-dropdown
            @command="AppOnSelect"
            trigger="click"
          >
            <span
              class="color-F84E4E font-size-24 border-bottom-2-solid-F84E4E font-weight-500 cursor-pointer-hover display-block padding-bottom-5"
            >
              {{ AppSelectText.DICTIONARYITEM_ITEMNAME }}
              <i
                class="jeicon jeicon-sort-small-down font-size-20 margin-left-10"
              />
            </span>
            <el-dropdown-menu
              slot="dropdown"
              style="width: 100px"
            >
              <el-dropdown-item
                v-for="(select, _select) in AppSelect"
                :key="_select"
                :command="select"
              >
                <span>
                  {{ select.DICTIONARYITEM_ITEMNAME }}
                </span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-col>
      <el-col
        :span="11"
        class="elemnt-input-YGDD"
      >
        <span class="font-size-16 color-3F3F3F vertical-align-middle">关键词：</span>
        <el-input
          v-model="keyWord"
          @change="changeKeyword"
          clearable
          class="border-none vertical-align-middle display-inline"
          placeholder="APP名称/APPID"
        >
          <i
            slot="prefix"
            :class="keyWord && 'cursor-pointer-hover-F9514F' || ''"
            class="el-input__icon el-icon-search"
          />
        </el-input>
      </el-col>
      <el-col
        :span="9"
      >
        <div class="text-status-right">
          <!-- <el-button
            :disabled="!currentPanel.userInfo.customerId"
            @click="APP_getAppShopInfo()"
            :style="currentPanel.userInfo.customerId && 'background: rgb(239, 77, 77);color: rgb(255, 255, 255);border-color: rgb(239, 77, 77);'"
            type="danger"
          >
            导入已购买的APP证书
          </el-button> -->
          <el-button
            :disabled="!currentPanel.userInfo.customerId"
            @click="currentPanel.list.getList()"
            :style="currentPanel.userInfo.customerId && 'background: rgb(241, 155, 44);color: rgb(255, 255, 255);border-color: rgb(241, 155, 44);'"
            type="danger"
          >
            <i class="jeicon jeicon-table-column-synchronization font-size-14" />
            刷新
          </el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {
  getAppShopInfo,
} from '@/pages/index/actions/actions';

export default {
  props: {
    currentPanel: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      keyWord: '',
    };
  },
  computed: {
    AppSelectText() {
      return this.currentPanel.list.search.AppSelectText || '全部';
    },
    AppSelect() {
      return this.currentPanel.list.search.AppSelect || [];
    },
  },
  methods: {
    APP_getAppShopInfo() {
      getAppShopInfo({ customerId: this.currentPanel.userInfo.customerId }).then((res) => {
        if (!res.success) return this.$message.error(res.message);
        this.$message.success('导入APP证书成功');
        this.currentPanel.list.getList();
      });
    },
    // 关键词搜索
    changeKeyword(val) {
      this.currentPanel.list.search.keyWordChange(val);
    },
    /*
     * 获取当前下拉选中的数据
     */
    AppOnSelect(data) {
      if (!data) return;
      this.currentPanel.list.search.AppOnSelect(data);
    },
  },
};
</script>

<style lang="less" scoped>
@import '../../../../style/shop/util';
</style>
